<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */
import { defineAsyncComponent, onMounted, ref } from "vue";
import type { SecondaryDataInterface } from "@/types/ContentType";

import { isInView } from "@/composables/utils/isInView";

const isTrigger = ref(false);
const compEl = ref(null);

const EmptyBorders = defineAsyncComponent(() => import("./parts/EmptyBorders.vue"));
const ContactInfo = defineAsyncComponent(() => import("./parts/ContactInfo.vue"));

const GoogleMap = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/GoogleMap/GoogleMap.vue")
);

onMounted(() => {
  window?.addEventListener("scroll", () => {
    isTrigger.value = isInView(compEl.value!, 1);
  });
});

defineProps<{
  contentData?: SecondaryDataInterface;
}>();
</script>

<template>
  <section class="contact-block bg-black" ref="compEl">
    <div class="contact-block__dummy pos-abs ev-none"></div>
    <EmptyBorders class="top" />
    <div class="contact-block__inner bg-black d-flex">
      <ContactInfo :contentData="contentData" />
      <GoogleMap class="contact-block__map" :contentData="contentData" v-if="isTrigger" />
    </div>
    <EmptyBorders class="bottom" />
  </section>
</template>

<style lang="scss" src="./styles/contactBlock.scss" />
